<template>
  <EmptyTrackingPage v-if="isEmpty" />
  <MainPage
    v-else
    :error-message="errorMessage"
    data-cy="list-tracking-pages-page"
    :heading="mainPageConfig.heading"
    :tagline="mainPageConfig.tagline"
    :loading="isLoading"
    @wipe-error="wipeError"
  >
    <template #actionsBar>
      <ContactSupportBtn />
    </template>
    <template #body>
      <div>
        <div class="card shadow list-tracking-pages">
          <div class="card-body">
            <div class="container-fluid">
              <TrackingPagesTable :tracking-pages="trackingPages" />
            </div>
          </div>
        </div>
      </div>
    </template>
  </MainPage>
</template>

<script setup>
import MainPage from "@/components/MainPage.vue";
import { useTrackingPageStore } from "@/components/tracking-pages/stores/tracking-page";
import { storeToRefs } from "pinia/dist/pinia";
import { computed, onMounted, ref } from "vue";
import TrackingPagesTable from "@/components/tracking-pages/TrackingPagesTable";
import ContactSupportBtn from "@/components/ContactSupportBtn";
import EmptyTrackingPage from "@/components/tracking-pages/EmptyTrackingPage";

const isLoading = ref(true);
const errorMessage = ref(null);

const wipeError = () => {
  errorMessage.value = "";
};

const store = useTrackingPageStore();
const { getTrackingPages } = store;
const { trackingPages } = storeToRefs(store);

const mainPageConfig = computed(() => {
  return {
    heading: isEmpty.value ? "" : "Branded Tracking Pages",
    tagline: isEmpty.value
      ? ""
      : "Personalise your branded tracking page content to drive traffic and increase engagement.",
  };
});

const getAllTrackingPages = async () => {
  isLoading.value = true;
  try {
    await getTrackingPages();
  } catch {
    errorMessage.value = "Failed to load.";
  }
  isLoading.value = false;
};

const isEmpty = computed(() => !isLoading.value && !trackingPages.value.length);
onMounted(async () => {
  await getAllTrackingPages();
});
</script>
<style lang="scss" scoped>
.list-tracking-pages {
  :deep(.card-body) {
    padding: 32px;
  }
  :deep(.container-fluid) {
    padding: 0;
  }
}
</style>
